























import { defineComponent, computed, PropType } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { ICustomerIconImage, ICustomerIconProps } from './types/CustomerIcon';

const DEFAULT_IMG = {
  filename: 'https://a.storyblok.com/f/152150/106x106/4e41945265/fc-customer-review-avatar-male-icon-001.png',
  alt: 'customer review male avatar',
};

export default defineComponent({
  name: 'CustomerIcon',
  components: {
    FCImage,
  },
  props: {
    image: {
      type: Object as PropType<ICustomerIconImage>,
      required: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: ICustomerIconProps) {
    return {
      validImage: computed(() => {
        if (props.image && props.image?.filename !== '') return props.image;

        return DEFAULT_IMG;
      }),
    };
  },
});
